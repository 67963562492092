import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'


import styled from 'styled-components';
import Grid from '../Helpers/Grid'


const ImageWraper = styled.div`

  position: relative;
  text-align: center;
  color: white;

  img {
      margin-bottom: 16px;
      object-fit: cover; 
      border-radius: 100%;
      background-color: #f1f1f1;
      width: 300px;
      height: 300px;
  }  

`

const DateWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);   

    /*new:*/
    font-size: 70px;
    font-weight: 100;    
    line-height: 60px;
    text-indent: 10px;

    font-family: "Fanwood Text";
    font-style: normal;
    font-variant-caps: normal;
    color: white;
    text-shadow: 0 0 6px #000; /* horizontal-offset vertical-offset 'blur' colour */
    -moz-text-shadow: 0 0 6px #000;
    -webkit-text-shadow: 0 0 6px #000;
`  


const EventWraper = styled.div`
  display: block;
  flex-direction: column;
  margin-bottom: 2px;
  //border: 1px solid red;
  cursor: pointer;
  justify-content: flex-start;
  text-align: center;
  margin-bottom: 40px;  

  img {
    height: 300px; 
    width: 300px;
    object-fit: cover; 
    border-radius: 50%;
    background-color: #f1f1f1;
  }

  @media screen and (max-width: 960px) {
    img {
      height: 200px; 
      width: 200px;
    }
  }  

`

const Event = ( { node }) => {

  const image = getImage(node.frontmatter.image);
  const date = node.frontmatter.date;

  return (
    <EventWraper>
      <Link to={`/${node.slug }`}>
        <ImageWraper>      
          <GatsbyImage image={image} />
          <DateWrapper>{date}</DateWrapper>
        </ImageWraper>
          <h2>{ node.frontmatter.title }</h2>
      </Link>
    </EventWraper>      
  )
}

const ColumnWrapper = styled.div`
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
`

const Categories = ({ data, columns }) => {

  return(
    <Grid columns={columns} style={{ textAlign: "center" }}>           
      {
        data.allMdx.nodes.map(node => {

          return (
            <ColumnWrapper> 
              <Event node={node}/>                  
            </ColumnWrapper>
          )

        })
      }
    </Grid>    
  )
}

export default Categories;