import React from 'react'
import { graphql } from 'gatsby'

import styled from 'styled-components';


import Layout from '../../components/Layout'
import Calendar from '../../components/Calendar'

const EventsWraper = styled.div`
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
  height: auto;
  width: 70vw;  
  @media screen and (max-width: 960px) {
    width: 90vw;  
    margin-bottom: 40px;
  }  
`

  

const Events = ({ data }) => {
  return (
    <Layout section="events" pageTitle="Events">
      <EventsWraper>      
        <h1>Events</h1>
        <p>Here you will find all the activities we are planning before our wedding.</p><br/>        
        <Calendar data={data} columns="3" />
      </EventsWraper>
    </Layout>
  )
}

export const query = graphql`
  query {
    sitePage {
	    path
    }    
    allMdx(filter: { frontmatter: { category : { eq: "events" } } }, sort: {fields: frontmatter___date, order: ASC }) {
      nodes {
        frontmatter {
          title
          date(formatString: "MMMM DD")
          image_alt
          category
          image {
            childImageSharp {
              gatsbyImageData(width: 500)
            }
          }          
        }    
        id
        excerpt(pruneLength: 150)
        slug
      }
    }
  }
`



export default Events